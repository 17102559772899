<template>
    <div class="Downloa">
        <div class="banner">
            <div class="text">
                <p>Secured. Hi-Tech. Innovative</p>
                <h2>Your All-in-One Trading Platform</h2>
                <div class="hr"></div>
                <ul>
                    <li>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                        <div class="pro">
                            <p>All-in-One</p>
                            <p>Trading Platform</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                        <div class="pro">
                            <p>Instant Funding</p>
                            <p>Transfer</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                        <div class="pro">
                            <p>1:1</p>
                            <p>Education</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                        <div class="pro">
                            <p>Instant</p>
                            <p>Transactions</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                        <div class="pro">
                            <p>Latest</p>
                            <p>Info</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                        <div class="pro">
                            <p>Real Time</p>
                            <p>Quotation</p>
                        </div>
                    </li>
                </ul>
                <div class="btn_down">
                    <img class="cursor" @click="linkToAppleFun" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/down_qr_ios%402x.png" alt="">
                    <img class="cursor" @click="linkToAndroid" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/down_qr_android%402x.png" alt="">
                    <!-- <img class="cursor" @click="linkToGoogle" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/down_qr_google%402x.png" alt=""> -->
                </div>
                <div class=""></div>
            </div>
            <div class="btn_img"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_pic_iphone12-1%402x.png" alt=""></div>
            
        </div>
        <!-- <div class="diversified">
            <h2>Diversified Investment  Portfolio</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_icon_001%402x.png" alt="">
                    <p>Forex</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_icon_002%402x.png" alt="">
                    <p>Indices</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_icon_003%402x.png" alt="">
                    <p>Commodities</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_icon_004%402x.png" alt="">
                    <p>Precious Metals</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_icon_005%402x.png" alt="">
                    <p>Energies</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_icon_006%402x.png" alt="">
                    <p>Cryptocurrencies</p>
                </li>
            </ul>
            <p style="font-size:24px;">Cover 90% And 100+</p>
            <p>Types of Most Traded Financial Instruments</p>
        </div>
        <div class="banner2">
            <div class="text">
                            <h2>The Top Trading Strategic Research Team</h2>
                            <div class="hr"></div>
                            <ul>
                                <li>
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                                    <div class="pro">
                                        <p>Most Advanced</p>
                                        <p>Trading Analysis</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                                    <div class="pro">
                                        <p>Most Accurate</p>
                                        <p>Market Forecasts</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                                    <div class="pro">
                                        <p>Latest</p>
                                        <p>Market Info</p>
                                    </div>
                                </li>
                                  <li>
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/icon_arrow%402x.png" alt="">
                                    <div class="pro">
                                        <p>Investment</p>
                                        <p>Tutorials</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_pic_team%402x.png" alt="">
        </div>
        <div class="banner3">
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_pic_100000%2B%402x.png" alt="">
            <div class="text">
                <h2>Professional Investing Community for Traders</h2>
                <div class="box">
                    <p class="title">100,000 +</p>
                    <p>Users Worldwide</p>
                </div>
            </div>
        </div>
        <div class="banner4">
            <div class="text">
                <h2>Trade Easy    Trade Smart</h2>
                <div class="btn_down">
                    <img class="cursor" @click="linkToAppleFun" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/btn_download_app%20%20store%402x.png" alt="">
                    <img class="cursor" @click="linkToGoogle" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/btn_download_google%20play%402x.png" alt="">
                    <img class="cursor" @click="linkToAndroid" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/btn_android%402x.png" alt="">
                </div>
            </div>
            <img class="pic" src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/download_pic_advantrade%402x.png" alt="">
        </div> -->
        <!-- <div class="content_one">
            <div class="content_one_box w1200">
                <div class="box_left">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/pic_software%402x.png" alt="">
                </div>
                <div class="box_right">
                    <div class="box_text"><h2>Download MetaTrader 5</h2>
                    <h3>Click to download</h3></div>
                    
                    <div class="downQR">
                        <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=TopicalWealthIntl-Demo,TopicalWealthIntl-Live"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/mt5_down_qr_ios%402x.png" alt=""></a>
                        <a href="https://twi.oss-cn-hangzhou.aliyuncs.com/app/metatrader5.apk"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/mt5_down_qr_android%402x.png" alt=""></a>
                        <a href="https://download.mql5.com/cdn/web/18873/mt5/topicalwealthintl5setup.exe"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/mt5_down_qr_pc%402x.png" alt=""></a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="content_two">
            <div class="content_two_box w1200">
                <div class="box_left">
                    <h2>Download MetaTrader 4</h2>
                    <h3>Click to download</h3>
                    <div class="downQR">
                        <a href="https://download.mql5.com/cdn/mobile/mt4/ios?server=TopWealthIntl-Demo,TopWealthIntl-Live"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/mt4_down_qr_ios%40MT4.png" alt=""></a>
                        <a href="https://regainsite.oss-ap-southeast-1.aliyuncs.com/platform/metatrader4_400.1179.apk"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/mt4_down_qr_android%40MT42.png" alt=""></a>
                        <a href="https://download.mql5.com/cdn/web/16704/mt4/topwealthintl4setup.exe"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/mt4_down_qr_pc%40MT4.png" alt=""></a>
                    </div>
                </div>
                <div class="box_right">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/home%EF%BC%8Fpic_mt4%402x%20(2).png" alt="">
                </div>
            </div>
        </div> -->
   
   </div>
</template>
<script>
import apiService from '@/api/request';
import { linkToGoogle,linkToApple,linkToAndroid } from '@/until/common';

export default {
    name:'Downloa',
    setup(){
   function linkToAppleFun(){
                linkToApple();
            }
       return {
        linkToGoogle,
        linkToApple,
        linkToAppleFun,
        linkToAndroid
       }
    },
     components:{
    },
}
</script>
<style lang="less" scoped>
.Downloa{
    .banner,.banner2{
        height: 640px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFF1E9 100%);
        overflow: hidden;
        .text{
            height: 336px;/*no*/
            text-align: left;
            margin-right: 100px;
            p{
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                color: #424242;
            }
        }
        h2{
            font-size: 24px;
            line-height: 29px;
            color: #FB741B;
            margin-top: 16px;
            font-weight: bold;
        }
        .btn_img{
          
            img{
            width: 444px;
            height: 570px;
        margin-top: 82px;
           
        }
        }
        
        .hr{
            height: 1px;/*no*/
            background: #FB741B;
            margin-top: 27px;   
        }
        ul{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 596px;
            margin-top: 30px;
            li{
                width: 196px;
                margin-bottom: 16px;
                display: flex;
                justify-content: left;
                p{
                    font-weight: 400;
color: #0E0E0E;
                }
                img{
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    position: relative;
                    top: 3px;
                }
                .pro{

                }
            }
        }
        .btn_down{
            display: flex;
            justify-content: left;
            margin-top: 9px;
            img{
                display: block;
                width: 158px;
                height: 190px;
                margin-right: 21px;
            }
        }
    }
    .content_one{
            padding-top: 118px;
            padding-bottom: 100px;
            background: white;
            height: 520px;
            .content_one_box{
                display: flex;
                justify-content: space-between;
            }
            .box_left{
                width: 635px;
                height: 282px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .box_right{
                text-align: left;
                height: 100%;
                .box_text{
                margin-bottom: 50px;
               }
                h2{
                    font-size: 24px;
                  margin-bottom: 10px;
                    font-weight: 600;
                    line-height: 33px;
                    color: #FB741B;
                    
                }
                h3{
                    color: #FB741B;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                }
                .downQR{
                    margin-bottom: 30px;
                    img{
                        width: 158px;
                        height: 190px;
                    }
                }
            }
        }
    
     .content_two{
           padding-top: 118px;
            padding-bottom: 125px;
            background: linear-gradient(180deg, #FFFFFF 0%, #EDEDED 100%);
            .content_two_box{
                display: flex;
                justify-content: space-between;
              position: relative;
            }
            .box_left{
                text-align: left;
                width: 500px;
                h2{
                    font-size: 24px;
                    margin-bottom: 10px;
                    font-weight: 600;
                    line-height: 33px;
                    color: #FB741B;
                    
                }
                h3{
                    color: #FB741B;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                }
                .downQR{
                    margin-top: 40px;
                    img{
                        width: 158px;
                        height: 190px;
                    }
                }
            }
            .box_right{
                width: 634px;
                height: 346px;
                padding-top: 7px;
                position: absolute;
                left: 550px;
                top: 76px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    .diversified{
        background: #FB741B;
        padding: 57px 0 41px 0;
        h2{
            color: white;
            font-size: 30px;
            
        }
        ul{
            width: 750px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto;
            margin-top: 39px;
            li{
                width: 250px;
                color: white;
                height: 100px; /*no*/
            }
            img{
                width: 48px;
            }
            p{
                margin-top: 13px;
            }

        }
        p{
            color: white;
        }
    }
    .banner2{
        padding-top: 45px;
        padding-bottom: 41px;
        h2{
            width: 363px;
        }
        .text{
            // height: 263px;/*no*/
            margin-right: 193px;
        }
        ul{
            width: 412px;
            li{
            }
        }
        img{
            width: 360px;
        }
    }
    .banner3{
        background: #FB741B;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 40px;
        img{
            width: 360px;
            margin-right: 146px;
        }
        .text{
            width: 333px;/*no*/
            color: white;
            padding-top: 79px;
        }
        h2{
            font-size: 30px;
            text-align: left;
        }
        .box{
            width: 200px;
            height: 88px;
            border: 1px solid white;
            padding-top: 12px;
            margin-top: 32px;   
            .title{
                font-size: 30px;
            }
        }
    }
    .banner4{
        background: linear-gradient(180deg, #FFFFFF 0%, #FFF1E9 100%);
        display: flex;
        justify-content: center;
        .pic{
            width: 500px;
        }
        .text{
            width: 400px;
            padding-top: 140px;
            margin-right: 105px;
            h2{
                font-size: 30px;
                color: #FB741B;
                text-align: left;
            }
            .btn_down{
                display: flex;
                justify-content: left;
                flex-wrap: wrap;
                img{
                    margin-top: 46px;
                    width: 160px;
                    margin-right: 21px;
                }
            }
        }
    }
}
</style>

